// require jQuery normally
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

require('./libs/flickity.pkgd.min');
require('./libs/easyXDM.min');
require('./libs/featherlight.min');
require('./libs/jquery.transit.min');
require('./libs/jquery.autocomplete.min');

require('./modules/accordion');
require('./modules/choose-contact-person');
require('./modules/form');
require('./modules/header');
require('./modules/image-slider');
require('./modules/keyvisual');
require('./modules/map');
require('./modules/mediathek');
require('./modules/press-contactform');
require('./modules/press-dates');
require('./modules/press-kit');
require('./modules/products');
require('./modules/quotes');
require('./modules/reference');
require('./modules/registrationform');
require('./modules/search');
require('./modules/sidebar-faq');
require('./modules/sidebar-shares');
require('./modules/sticky-elements');
require('./modules/tabs');
require('./modules/teasers');
require('./modules/teasers-press');
require('./modules/teasers-story');
require('./modules/teasers-white');
require('./modules/upgrades');
require('./modules/video');

require('./partials/header');
require('./partials/investor-relations-page');
require('./partials/main-nav');

import '../sass/styles.scss';